import { useNavigate } from 'react-router-dom';
import { SignInUserRequest, signInUser } from '../../../../api/user';
import { useAppDispatch } from '../../../../store/store';
import TitledTextBox from '../../../components/TitledTextBox/TitledTextBox';
import { StyledSignInContainer } from './Styles';
import { useForm } from 'react-hook-form';
import YellowButton from '../../../components/YellowButton/YellowButton';

export const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, handleSubmit } = useForm<SignInUserRequest>();

  const onSubmit = (data: SignInUserRequest) => {
    signIn(data);
  };

  const signIn = (data: SignInUserRequest) => {
    dispatch(signInUser(data)).then(({ payload }) => {
      if (payload) {
        navigate('/dashboard');
      } else {
        alert(
          'There was a problem during sign in. Please double check your information.',
        );
      }
    });
  };

  return (
    <StyledSignInContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitledTextBox
          title={'Email'}
          label={'email'}
          register={register}
          required
        />
        <TitledTextBox
          title={'Password'}
          label={'password'}
          register={register}
          password
          required
        />
        <YellowButton title={'Sign In'} />
      </form>
    </StyledSignInContainer>
  );
};
