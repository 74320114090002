import { useNavigate } from 'react-router-dom';
import { SidebarContainer, NavList, NavItem, LogOutButton } from './Styles';
import { logOutUser } from '../../../api/user';
import { useAppDispatch } from '../../../store/store';

const NavigationSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logOut = () => {
    dispatch(logOutUser());
  };

  return (
    <SidebarContainer>
      <div>
        <NavList>
          <NavItem onClick={() => navigate('/dashboard')}>Dashboard</NavItem>
          <NavItem onClick={() => navigate('/projects')}>Projects</NavItem>
          <NavItem onClick={() => navigate('/habits')}>Habits</NavItem>
          <NavItem onClick={() => navigate('/calendar')}>Calendar</NavItem>
          <NavItem onClick={() => navigate('/settings')}>Settings</NavItem>
        </NavList>
      </div>
      <LogOutButton onClick={() => logOut()}>Log Out</LogOutButton>
    </SidebarContainer>
  );
};

export default NavigationSidebar;
