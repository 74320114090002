import { useNavigate } from 'react-router-dom';
import { SignUpUserRequest, signUpUser } from '../../../../api/user';
import { useAppDispatch } from '../../../../store/store';
import TitledTextBox from '../../../components/TitledTextBox/TitledTextBox';
import { StyledSignUpContainer } from './Styles';
import { useForm } from 'react-hook-form';
import YellowButton from '../../../components/YellowButton/YellowButton';

export const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, handleSubmit } = useForm<SignUpUserRequest>();

  const onSubmit = (data: SignUpUserRequest) => {
    signUp(data);
  };

  const signUp = async (data: SignUpUserRequest) => {
    dispatch(signUpUser(data)).then(({ payload }) => {
      if (payload) {
        navigate('/dashboard');
      } else {
        alert(
          'There was a problem during sign up. Please double check your information.',
        );
      }
    });
  };

  return (
    <StyledSignUpContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitledTextBox
          title={'First Name'}
          label={'firstName'}
          register={register}
          required
        />
        <TitledTextBox
          title={'Last Name'}
          label={'lastName'}
          register={register}
          required
        />
        <TitledTextBox
          title={'Email'}
          label={'email'}
          register={register}
          required
        />
        <TitledTextBox
          title={'Password'}
          label={'password'}
          register={register}
          password
          required
        />
        <YellowButton title={'Sign Up'} />
      </form>
    </StyledSignUpContainer>
  );
};
