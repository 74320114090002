import { useState } from 'react';
import { SignUp } from './components/SignUp/SignUp';
import { SignIn } from './components/SignIn/SignIn';
import { useAppSelector } from '../../store/store';
import { selectUser } from '../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import logo from './../../assets/setup.png';

import {
  StyledLandingColumnLeft,
  StyledLandingColumnRight,
  StyledLandingContainer,
  StyledLandingLogo,
  StyledLink,
  TextContainer,
} from './Styles';

const Landing = () => {
  const [userSigningIn, setUserSigningIn] = useState(true);
  const { isAuthenticated } = useAppSelector(selectUser);
  const navigate = useNavigate();

  const handleSwitch = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setUserSigningIn(!userSigningIn);
  };

  if (isAuthenticated) {
    navigate('/dashboard');
  }

  return (
    <StyledLandingContainer>
      <StyledLandingColumnLeft>
        <StyledLandingLogo src={logo} />
      </StyledLandingColumnLeft>
      <StyledLandingColumnRight>
        <TextContainer>
          {userSigningIn ? (
            <>If you would like to create an account, sign up</>
          ) : (
            <>If you already have an account, sign in</>
          )}{' '}
          <StyledLink onClick={(event) => handleSwitch(event)}>here</StyledLink>
        </TextContainer>
        {userSigningIn ? <SignIn /> : <SignUp />}
      </StyledLandingColumnRight>
    </StyledLandingContainer>
  );
};

export default Landing;
