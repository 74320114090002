import styled from 'styled-components';
import { device } from '../../../themes/breakpoints';

export const SidebarContainer = styled.div`
  height: 100vh;
  width: 15vw;
  min-width: 150px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primaryText};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 999;

  @media ${device.sm} {
    width: 100vw;
    height: 10vh;
    flex-direction: row;
  }
`;

export const NavList = styled.ul`
  list-style-type: none;
  padding: 0;

  @media ${device.sm} {
    display: flex;
    flex-direction: row;
  }
`;

export const NavItem = styled.li`
  padding: 10px;
  font-size: 18px;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const LogOutButton = styled.button`
  font-size: 18px;
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.primaryText};
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
