import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledLandingContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (${device.lg}) {
    flex-direction: column;
  }
`;

export const StyledLandingColumnLeft = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (${device.lg}) {
    height: 50%;
  }
`;

export const StyledLandingLogo = styled.img`
  height: 100%;
  width: 100%;
`;

export const StyledLandingColumnRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div``;

export const StyledLink = styled.a`
  color: red;
  cursor: pointer;
`;
